@import "../dashboard_variables.scss";

.container {
	@include container;

	.content {
		@include content;

		.summary {
			justify-content: space-between;

			@media screen and (max-width: $size-tablet) {
				justify-content: center;
			}
		}

		.scroll {
			overflow-x: auto;

			.area {
				min-width: 20em;
			}
		}

		.table {
			width: 100%;
			max-width: none;
		}
	}
}
