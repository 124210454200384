.error_view {
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr;
    align-items: center;
    justify-items: center;

    &__header {
        &.MuiAppBar-root {
            align-items: flex-end;
            background-color: white;
            box-shadow: none;
        }

        &__logo {
            max-height: 100px;
            max-width: 250px;
            object-fit: contain;
            width: 100%;
        }
    }

    &__content {
        width: 100%;
        display: grid;
        justify-items: center;
        grid-gap: 20px;
    }

    &__title {
        margin: 0;
        font-size: 25px;
    }
}
