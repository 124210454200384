@import "../dashboard_variables.scss";

.container {
	padding: 2em;
	background-color: white;
	border-radius: var(--border-radius);
	max-width: 20em;

	.no_data {
		font-weight: bold;
		color: #999;
		text-align: center;
	}

	.table_divided {
		gap: 1em;
	}

	@media screen and (max-width: $size-tablet-bigger) {
		width: 100%;
		max-width: none;
	}
}
