.container {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 100%;
	align-content: start;
	justify-items: center;
	background-color: #faf8f8;
	max-width: none;
	margin: 0;
}

.content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1em;
	width: 100%;
	padding: 20px;
	max-width: 1200px;

	.title {
		width: 100%;
	}

    .tab {
        display: flex;
        align-items: center;
        gap: 0.5em;
        padding-inline: 0.5em;
    }

	.profile {
		width: 100%;
		display: flex;
		gap: 2em;
		align-items: flex-start;
        padding: 2em;

		&__basic {
			display: grid;
			gap: 1em;
			justify-items: center;
		}

		&__data {
			display: grid;
			gap: 0.5em;
			justify-items: end;
            align-items: center;
            width: 100%;
            max-width: 20em;
            grid-template-columns: auto 1fr;

			&__field {
				padding: 0.5em 0.8em;
				background-color: white;
				border-radius: 10px;
				position: relative;
				width: 100%;
                grid-column: 2 / 3;

				&__label {
					position: absolute;
					top: 0;
					left: 1.2em;
					font-size: 0.7em;
					transform: translateY(-50%);
					color: gray;
				}
			}
		}
	}
}
