header {
    display: flex;
    max-width: 100%;
    margin: 0 auto;
    flex-direction: column;
}

.header__menuPrincipal {
    display: flex;
    padding: 15px 0;
}

.header__botonera {
    display: flex;
    flex-grow: 1;
    transform: translate3D(0, -50px, 0);
    transition: transform 0.26s ease;
    &.active {
        visibility: visible;
        height: auto;
        opacity: 1;
        transform: translate3D(0, 0, 0);
    }
}

.header__btn_menu {
    font-weight: 400 !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
    color: #284056 !important;
    opacity: 0.8 !important;
    letter-spacing: 0px !important;
}

.header__container_logo {
    width: 200px;
}

.header__user {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3D(0, -50px, 0);
    transition: transform 0.26s ease;
    &.active {
        visibility: visible;
        height: auto;
        opacity: 1;
        transform: translate3D(0, 0, 0);
    }
}

.active {
    color: var(--app-primaryColor) !important;
}

.mobile-btn {
    margin: 16px auto 0px auto;
    display: block;
    color: #000;
    height: 36px;
    width: 36px;
    border-top: 4px solid;
    position: relative;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    &::after,
    &::before {
        content: "";
        display: block;
        height: 4px;
        margin-top: 6px;
        background: currentColor;
        position: relative;
        transition: transform 0.3s ease;
    }
    &.active {
        border-top-color: transparent;
        &::after {
            margin-top: 0px;
            top: 2px;
            transform: rotate(45deg);
        }
        &::before {
            margin-top: 0px;
            top: 6px;
            transform: rotate(135deg);
        }
    }
}

@media (min-width: 320px) {
    .header__menuPrincipal {
        display: contents;
    }
    .header__botonera {
        flex-direction: column;
        align-items: flex-start;
        visibility: hidden;
        height: 0px;
    }
    .header__user {
        visibility: hidden;
        height: 0px;
    }
    .header__container_logo {
        img {
            width: 75%;
        }
    }
}

@media(min-width: 720px) {
    .header__menuPrincipal {
        display: flex;
    }
    .mobile-btn {
        display: none;
    }
    .header__botonera {
        transform: translate3D(0, 0px, 0);
        flex-direction: initial;
        align-items: initial;
        visibility: visible;
        height: auto;
        display: flex;
    }
    .header__user {
        transform: translate3D(0, 0px, 0);
        flex-direction: initial;
        align-items: initial;
        visibility: visible;
        height: auto;
        display: flex;
    }
}