
.admin__home__errors {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1em;
    overflow: hidden;

    &__table {
        display: grid;
        grid-template-rows: auto 1fr;
        width: 100%;
        height: 100%;
        grid-gap: 20px;
        overflow: hidden;

        &__filter {
            margin: 0;
            max-width: none;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;

            &__input {
                max-width: 150px;
            }
        }
    }
}