@import "../dashboard_variables.scss";

.container {
	@include container;

	.content {
		@include content;

		.summary {
			grid-template-columns: auto 1fr auto;

			@media screen and (max-width: $size-tablet) and (min-width: $size-mobile) {
				grid-template-columns: auto auto;
				justify-content: center;
			}

			@media screen and (max-width: $size-mobile) {
				grid-template-columns: 100%;
			}
		}

		.statistics {
			grid-template-columns: 1fr auto;

			@media screen and (max-width: $size-tablet-bigger) {
				grid-template-columns: 100%;
			}

			.metadata {
				max-width: 50em;

				.legend {
					text-decoration: none;
					display: flex;
					align-items: center;
					gap: 0.5em;

					&__color {
						width: 1.5em;
						height: 1.5em;
					}

					&__image {
						max-width: 4em;
						max-height: 100%;
						object-fit: contain;
					}
				}
			}

			.scroll {
				overflow-x: auto;

				.bar {
					width: 100%;
					height: 100%;
					max-width: 50em;
					min-width: 27em;
				}
			}

			.donut {
				width: 100%;
				max-width: 40em;
				max-height: 18em;
			}

			.table__total {
				font-size: 1.2em;
				font-weight: bold;
			}
		}
	}
}
