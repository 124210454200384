@import "./products_list.scss";
@import "./products_form.scss";

.products {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto 1fr;
	gap: 30px;
	justify-items: center;
	align-items: start;

	@include products_list;
	@include products_form;
}
