.modal__change_pass {

    &__icon.MuiSvgIcon-root {
        width: 4em;
        height: 4em;
    }

    &__text {
        text-align: center;
        margin: 0;
    }
}