.donut {
    *[class*="recharts-wrapper"] {
        max-width: 100%;
        max-height: 100%;

        &[class*="responsive"] {
            width: 100% !important;
            height: 100% !important;
        }
    }

    *[class*="recharts-surface"][class*="responsive"] {
        width: 100%;
        height: 100%;
    }

    &__text {
        text-align: center;
        color: #999;
        margin: 0;
    }
}
