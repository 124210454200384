body {
	.container {
		width: 100%;
		height: 100%;
		gap: 1em;
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: auto 1fr;
		justify-items: end;
		align-items: start;
		overflow-y: hidden;
	}

	.new_button {
		font-weight: bold;
	}

	.actions {
		display: flex;
		gap: 0.3em;
		align-items: center;

		&__icon {
			padding: 0.3em;
		}
	}

	.data_grid {
		--row--aligment: center;
	}

	.form_modal {
		max-width: 23em;
		gap: 1.3em;
		padding: 2em;
		overflow-y: auto;
		--textfield-gray-color: #9e9e9e;
		--textfield-border-radius: 5px;
		--textfield-shadow: 0 3px 3px #aaa;
		--textfield-pad: 0.5em;

		&__title {
			font-size: 1.5em;
		}

		&__inputs {
			width: 100%;
			display: grid;
			gap: 1em;
		}

		&__textfield {
			max-width: none;
		}

		&__textfield_read_only {
			border: 1px solid var(--textfield-gray-color);
			color: var(--textfield-gray-color);
			box-shadow: var(--textfield-shadow);
			padding: var(--textfield-pad) calc(2 * var(--textfield-pad));
			border-radius: var(--textfield-border-radius);
			line-height: normal;
		}

		&__select_list {
			max-width: none;

			&__select {
				--padding-inline: 1em;
			}
		}

		&__buttons {
			display: flex;
			justify-content: flex-end;
			gap: 1em;
			width: 100%;
			margin-top: 1.5em;
		}
	}
}
