.building_container {
    margin: 30px auto;
    max-width: 1192px;
}

.building__tarjetasInfo {
    display: flex;
    height: 500px;
    .building__tarjetaItem {
        width: 100%;
        height: 100%;
        box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.15), 5px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        text-align: center;
        margin: 0 auto;
        .building__tarjetaTitulo {
            font-weight: 900;
            font-size: 14px;
            color: #284056;
            margin: 0 0 12px 0;
        }
        .building__tarjetaData {
            font-weight: bold;
            font-size: 30px;
            color: var(--app-primaryColor);
            margin: 0;
        }
    }
}

main {
    &.building__tarjetasInfo {
        margin: 30px auto;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 320px) {
    .building_container {
        width: 90%;
    }

    .building__tarjetaItem {
        padding: 200px 0;
    }
}

@media (min-width: 768px) {
    .building_container {
        width: 90%;
    }

    .building__tarjetaItem {
        padding: 200px;
    }
}
