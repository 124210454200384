.container {
	display: grid;
	grid-template-rows: auto 1fr;
	width: 100%;
	gap: 1.5em;
	min-height: 40em;

	.header {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}
}

body .modal_associate_new {
	max-width: 30em;
	overflow-y: auto;

	&__select {
		max-width: 23em;
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-self: end;
		gap: 1em;
		margin-top: 1em;
	}
}

body .modal_delete_associated_branches {
	max-width: 50em;
	grid-template-rows: auto 1fr auto;
	height: 38em;

	&__buttons {
		display: flex;
		align-items: center;
		justify-self: end;
		gap: 1em;
	}
}
