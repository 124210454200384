.financier__whitelabel__form {
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    height: 100%;

    .whitelabel__form {
        margin: 0;
        padding: 40px;
        max-width: 1500px;
        justify-self: center;
        width: 100%;
    }

    &__container {
        display: grid;
        grid-template-rows: auto 1fr;
        width: 100%;
        height: 100%;
    }

    &__toolbar {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: flex-start;
        max-width: none;
        margin: 0;

        &__buttons {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            flex-wrap: wrap;
            flex-grow: 1;
        }

        &__textfield {
            max-width: 200px;
            width: 100%;
        }
        &__link {
            color: inherit;
            text-decoration: none;
        }
    }

    &__table {
        display: grid;
        grid-template-rows: auto 1fr;
    }

    &__panel {
        height: 100%;

        .MuiBox-root {
            height: 100%;
            padding: 0;
        }
    }
}

.tableEdit {
    color: var(--app-primaryColor);
    padding: 10%;
}

@media (min-width: 320px) {
    .MuiGrid-spacing-xs-1 {
        width: 100% !important;
        margin: 0px !important;
    }
    .MuiGrid-grid-xs-3 {
        flex-basis: 0 !important;
        margin: 0 auto !important;
    }
    #grid_user_financiers {
        div:nth-child(3) {
            a {
                display: grid !important;
                padding: 15px 10px !important;
            }
        }
    }
}

@media (min-width: 1366px) {
    .MuiGrid-spacing-xs-1 {
        width: calc(100% + 8px) !important;
        margin: -4px !important;
    }
    .MuiGrid-grid-xs-3 {
        flex-basis: 25% !important;
        margin: 0 !important;
    }
    #grid_user_financiers {
        div:nth-child(3) {
            a {
                display: block !important;
                padding: 15px 0px !important;
            }
        }
    }
}

.MuiTab-root {
    flex-direction: row !important;
    gap: 10px;
    text-transform: capitalize;
}

.form__edition {
    display: flex;
    max-width: 1192px;
    margin: 0 auto;
    flex-direction: column;
}