@import "../dashboard_variables.scss";

.card {
	--color: black;
	border-radius: var(--border-radius);
	background-color: var(--color);
	color: white;

	grid-auto-flow: column;
	padding: 10px;
	height: 5em;
	width: 20em;

	&__icon {
		font-size: 2em;
		margin-left: 0.5em;
	}

	& &__number {
		font-size: 1.5em;
		line-height: 1em;
		font-weight: bold;

		span {
			font-size: calc(1em / 3 * 2);
			font-weight: normal;
			line-height: 1em;
		}
	}

	&__link {
		align-self: start;
		border-radius: 100%;
		background-color: #d9d9d9;
		color: white;
		display: flex;
		padding: 3px;
		aspect-ratio: 1/1;
		justify-content: center;
		align-items: center;
		font-size: 0.8em;
	}

	&--total_clients {
		--color: #1e88e5;
	}

	&--total_offers {
		--color: #8884d8;
	}

	&--monthly {
		--color: #ca522b;
	}

	&--weekly_without_chart {
		--color: #ef933e;
	}

	&--weekly {
		--color: #ef933e;
		width: 100%;
		max-width: 30em;

		&__chart {
			min-height: 2.5em;
			z-index: 1;
			margin: 0;

			&:not(:hover) *[class*="recharts-tooltip-wrapper"],
			&:not(:hover) *[class*="recharts-tooltip-cursor"] {
				display: none;
			}
		}

		@media screen and (max-width: $size-tablet) and (min-width: $size-mobile) {
			grid-column: 1 / -1;
			grid-row-start: 2;
		}

		@media screen and (max-width: $size-mobile-small) {
			height: auto;
			align-items: start;

			> * {
				grid-template-columns: 100%;
			}
		}
	}

	@media screen and (max-width: $size-mobile) {
		width: 100%;
		max-width: none;
	}
}
