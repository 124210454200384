@import "./products_list_item.scss";

@mixin products_list {
	&__list {
		width: 100%;
		padding: 20px;
		display: grid;
		grid-template-columns: 100%;
		gap: 20px;
		max-width: 1200px;

		&__title {
			margin: 0;
			padding: 0;
		}

		&__new_button {
			font-weight: bold;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			gap: 5px 20px;
		}

		@include products_list_item;
	}
}
