.no_session__header {
    padding-inline: 10px;
    height: 80px;

    &__logo {
        width: 100%;
        max-width: 200px;
        height: 100%;
        object-fit: contain;
    }
}