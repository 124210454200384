@mixin products_form_inputs {
    &__cluster {
        display: grid;
        gap: 10px;
        width: 100%;
    }

    &__label {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        font-weight: bold;
    }

    &__textarea {
        width: 100%;
        max-width: 400px;
    }

    &__grouped_inputs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px 10px;
        align-items: start;
    }

    &__terms {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 10px;
        align-items: start;

        &__textfield {
            max-width: 150px;
        }

        &__label {
            margin: 0.5em 0;
            padding: 0;
            font-size: 1em;
        }
    }

    &__submit {
        justify-self: end;
        margin-top: 10px;
    }

    @media screen and (max-width: 850px) {
        &__grouped_inputs {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: 500px) {
        &__grouped_inputs {
            grid-template-columns: 100%;
        }
    }
}
