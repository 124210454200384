.MuiAppBar-colorPrimary {
    min-width: 100%;
}

.header__container_logo {
    width: 200px;
    height: 100%;

	img {
		width: 75%;
		object-fit: contain;
		height: 100%;
	}
}