:root {
	--app-primaryColor: #fcbb13;
	--app-secondaryColor: #fcbb13;
	--app-iconColor: #fcbb13;
	--app-selectionColor: #fcbb13;
	--app-iconImage: #fcbb13;
	--app-landingImage: none;
	--app-colorText: #000000;
	--app-colorMenu: #fff;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}

#root {
	overflow-y: auto;
}

body {
	background-color: #ffffff !important;
}

* {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.login__container,
.registro__container {
	display: flex;
	min-height: 100vh;
	@media (max-width: 768px) {
		flex-direction: column;
		flex-flow: column-reverse;
		min-height: unset;
	}
}

.container_back {
	position: absolute;
	left: 85px;
	top: 74px;

	&__button {
		background-color: white;
		border-radius: 100%;
	}
}

.login__form,
.registro__form {
	width: 55%;
	align-self: center;
	text-align-last: center;
	@media (max-width: 768px) {
		width: unset;
	}
}

form {
	margin: 0 30px 30px 100px;
	max-width: 560px;
	@media (max-width: 768px) {
		margin: 0 30px;
	}
}

h1.login {
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 59px;
	color: #284056;
	align-self: center;
	@media (max-width: 768px) {
		width: unset;
	}
	@media (max-width: 465px) {
		width: 100%;
	}
}

form {
	margin: 0 30px 30px 100px;
	max-width: 560px;
	@media (max-width: 768px) {
		margin: 0 25px;
	}
}

.login__input,
.registro__input {
	max-width: 400px;
	margin-bottom: 20px !important;
	width: 100%;
}

.Button {
	width: 400px !important;
	height: 55px;
	left: 100px;
	top: 557px;
	background: var(--app-primaryColor);
	border-radius: 3px;
}

.login__containerInput,
.registro__containerInput {
	display: flex;
	flex-direction: column;
	align-items: center;
	> span {
		color: #d9534f;
		font-size: 15px;
		padding: 10px 0;
	}
}

checkbox {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
}

.capaLogo {
	background-color: var(--app-primaryColor);
	width: 45%;
	@media (max-width: 768px) {
		width: 100%;
		height: 219px;
	}
}

.capaLogoImagen {
	background-image: var(--app-landingImage);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin: 0 auto;
}

.forgotPass {
	color: #284056;
	line-height: 20px;
}

@media (min-width: 320px) {
	.capaLogoImagen {
		width: 50%;
		height: 170px;
		margin-top: 4%;
	}
	.MuiTabs-root {
		.MuiTabs-fixed {
			overflow-x: scroll;
		}
	}
}

@media (min-width: 768px) {
	.capaLogoImagen {
		width: 50%;
		height: 323px;
	}
	.MuiTabs-root {
		.MuiTabs-fixed {
			overflow-x: hidden;
		}
	}
}

@media (min-width: 769px) {
	.capaLogoImagen {
		width: 370px;
		height: 50%;
		overflow: auto;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}
}

@media (min-width: 1024px) {
	.capaLogoImagen {
		width: 50%;
		height: 56%;
		overflow: auto;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}
}

@media (min-width: 1440px) {
	.capaLogoImagen {
		width: 50%;
		height: 86%;
		overflow: auto;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		margin-top: 5%;
		object-fit: contain;
	}
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
	padding: 12px;
}
