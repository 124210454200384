.bar {
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: 0;

	&__label_no_data {
		text-align: center;
		color: #999;
		margin: 0;
	}

	& &__label {
		text-align: right;
		margin: 0 auto;
		font-size: 0.8em;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;

		writing-mode: vertical-rl;
		transform-origin: center;
		transform: rotateZ(180deg);
	}

	*[class*="recharts-cartesian-grid-vertical"] line:last-child {
		display: none;
	}

	*[class*="recharts-cartesian-grid-horizontal"] line:last-child {
		display: none;
	}

	&:not(:hover) *[class*="recharts-tooltip-wrapper"],
	&:not(:hover) *[class*="recharts-tooltip-cursor"] {
		display: none;
	}
}
