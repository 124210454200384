.terminos__container {
    margin: 40px 0 0 0;
}

.terminos__titulo {
    font-weight: 600;
    font-size: 22px;
    color: #284056;
    margin: 0 0 9px 0;
}

.terminos__subtitulo {
    font-weight: normal;
    font-size: 18px;
    color: #284056;
    margin: 0 0 40px 0;
}

.terminos__form {
    // display: flex;
    align-items: center;
    .terminos_contInput {
        display: flex;
        flex-direction: column;
        width: 100%;
        // margin-right: 20px;
        .terminos__labelInput {
            font-weight: bold;
            font-size: 16px;
            color: #284056;
        }
        .terminos__input {
            width: 100%;
            height: 500px;
        }
        .terminos__btn {
            width: 223px !important;
            margin-top: 24px !important;
            text-transform: capitalize !important;
            font-weight: 900 !important;
            font-size: 16px !important;
        }
    }
}

@media (min-width: 320px) {
    .terminos__container {
        width: 100%;
        max-width: 100%;
    }
    .terminos__form {
        display: block;
        width: 90%;
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    .terminos__form {
        display: block;
    }
}

@media (min-width: 768px) {
    .terminos__form {
        display: flex;
        .terminos_contInput {
            margin-right: 20px;
        }
    }
}
