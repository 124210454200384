.container {
	width: 100%;
	min-height: 40em;
	padding: 2em;
	background-color: white;
	border-radius: var(--border-radius);

	.controls {
		grid-template-areas: "range_select date_range_input extra";
		grid-template-columns: auto 1fr auto;
		justify-items: center;

		.range_select {
			width: 12em;
			grid-area: range_select;
		}

		.date_range_input {
			grid-area: date_range_input;
		}

		.extra {
			grid-area: extra;
		}

		@media screen and (max-width: 1000px) {
			grid-template-areas:
				"range_select empty extra"
				"date_range_input date_range_input date_range_input";
			justify-items: start;
		}

		@media screen and (max-width: 600px) {
			grid-template-areas: "range_select" "date_range_input" "extra";
			grid-template-columns: 100%;
			justify-items: center;

			.range_select {
				width: 100%;
			}

			.extra {
				justify-self: end;
			}
		}
	}

	.controls_simple {
		grid-template-columns: auto 1fr;
		justify-items: start;

		.range_select {
			width: 12em;
			justify-self: start;
		}

		@media screen and (max-width: 800px) {
			grid-template-columns: 100%;
		}

		@media screen and (max-width: 600px) {
			justify-items: center;
		}
	}
}
