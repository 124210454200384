.header__menuOffset {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px double;
}

header {
    display: contents;
}

.header__offset {
    margin-left: 80%;
    margin-top: 1%;
}

.MuiTextField-root {
    width: 100%;
}