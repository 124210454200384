.form {
	width: 100%;
	max-width: 30em;
	margin: 0;
	padding: 2em;

	.title {
		font-size: 2.5em;
	}

	.input {
		max-width: none;
		width: 100%;
		font-size: 1.2em;
	}

	.button {
		padding: 0.5em 2em;
	}
}
