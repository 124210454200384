.lenders {
    &__title {
        color: var(--color--main);
    }

    &__table {
        width: 100%;
        height: 400px;
        display: grid;
    }
}

.lenders__replace{
    &__select {
        width: 100%;
    }
}

.lenders__edit {
    &__title {
        margin: 0;
    }
}