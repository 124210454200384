.subtitle {
	padding-left: 0.5em;
}

.paper {
	border: solid;
	padding: 2em;
}

.form {
	max-width: 100%;
	margin: 0;
	padding: 0;
}

.button {
	width: 100%;
	max-width: 10em;
	justify-self: end;
}