.container {
	display: grid;
	grid-template-columns: 100%;

	.content {
		display: grid;
		gap: 1em;
		grid-template-columns: 100%;
		padding: 20px 40px;

		.header {
			display: flex;
			align-items: center;
		}

		.cluster {
			width: 100%;
			display: grid;
			gap: 20px;
			background-color: #f5f5f5;
			border-radius: 10px;
			padding: 30px;
		}
	}
}
