.container {
	width: 100%;
	max-width: 60em;
	justify-self: center;
}

.paper {
	border: solid;
	padding: 2em;
}

.form {
	margin: 0;
	padding: 0;
	max-width: none;
}

.button {
	width: 100%;
	max-width: 10em;
	justify-self: end;
}
