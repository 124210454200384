.form {
	width: 100%;
	display: grid;
	gap: 20px;
	margin: 0;
	max-width: none;

	&__title {
		margin-bottom: 20px;
	}

	&__inputs {
		display: grid;
		gap: 20px;
		grid-template-columns: 1fr 1fr 1fr;
		width: 100%;
		align-items: start;
	}

	&__button {
		justify-self: end;
		width: 100%;
		max-width: 200px;
		border-radius: 40px;
		text-transform: capitalize;
	}
}
