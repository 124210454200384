@mixin products_list_item {
	&__items {
		display: grid;
		grid-template-columns: 100%;
		gap: 40px;
	}

	&__item {
		box-shadow: 0 0 10px rgba(100, 100, 100, 0.5);
		padding: 20px;
		display: grid;
		grid-template-columns: 1fr auto;
		justify-items: start;
		align-items: start;
		gap: 20px;

		&__cluster {
			display: grid;
			justify-items: start;
		}

		&__label {
			margin: 0;
			padding: 0;
			font-size: 0.7em;
		}

		&__text {
			margin: 0;
			padding: 0;

			&--small {
				font-size: 0.8em;
			}

			&--medium {
				font-size: 1em;
			}

			&--large {
				font-size: 1.5em;
			}

			&--bold {
				font-weight: bold;
			}
		}

		&__button {
			padding: 5px 30px;
			border-radius: 20px;
			background-color: #fff;
			border: 1px solid black;
			text-transform: capitalize;
			display: flex;
			gap: 10px;
			align-items: center;
			justify-content: center;
			color: black;
			font-size: 1em;

			&:hover {
				background-color: #e4e4e4;
			}

			svg {
				color: #a1a1a1;
			}
		}

		@include products_list_item_containers;
		@include products_list_item_responsive;
	}
}

@mixin products_list_item_containers {
	&__main {
		display: grid;
		gap: 10px;
		justify-items: start;
		max-width: 400px;
	}

	&__secondary {
		justify-self: end;
	}

	&__details {
		display: flex;
		gap: 5px 20px;
		flex-wrap: wrap;
		width: 100%;
	}

	&__buttons {
		display: flex;
		gap: 20px;
		align-self: end;
	}
}

@mixin products_list_item_responsive {
	@media screen and (max-width: 600px) {
		grid-template-columns: 100%;
		gap: 5px;

		&__details {
			margin-block: 20px;
		}

		&__secondary {
			grid-row-start: 1;
			justify-self: start;
		}

		&__buttons {
			display: grid;
			justify-items: center;
			width: 100%;
		}

		&__button {
			width: 100%;
			max-width: 250px;
		}
	}
}
