.header__menu_principal {
    display: flex;
    justify-content: space-between;
    padding-block: 15px;
    transform: translate3D(0, -50px, 0);
    transition: transform 0.26s ease;

    &.visible {
        visibility: visible;
        height: auto;
        opacity: 1;
        transform: translate3D(0, 0, 0);
    }
}
.header__buttons {
    display: flex;
    gap: 10px;
}

.MuiButtonBase-root.header__button {
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    color: #284056;
    opacity: 0.8;
    letter-spacing: 0px;
    display: flex;
    align-items: center;

    &--gap {
        gap: 5px;
    }

    .MuiButton-startIcon, .MuiButton-endIcon {
        margin: 0;
    }
}

.header__logo {
    width: 200px;
    img {
        width: 100%;
    }
}

.mobile_menu__button {
    margin: 16px auto 0px auto;
    display: block;
    color: #000;
    height: 36px;
    width: 36px;
    border-top: 4px solid;
    position: relative;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    &::after,
    &::before {
        content: "";
        display: block;
        height: 4px;
        margin-top: 6px;
        background: currentColor;
        position: relative;
        transition: transform 0.3s ease;
    }
    &--active {
        border-top-color: transparent;
        &::after {
            margin-top: 0px;
            top: 2px;
            transform: rotate(45deg);
        }
        &::before {
            margin-top: 0px;
            top: 6px;
            transform: rotate(135deg);
        }
    }
}

.module_header_action__container{
    width: 100%;
    max-width: 1200px;
    padding: 20px;
}
.MuiIconButton-root.module_header_action__arrow {
    padding: 0;
}

@media (min-width: 320px) {
    .header__menu_principal {
        display: flex;
        visibility: hidden;
        height: 0px;
        flex-direction: column;
        align-items: center;
    }
    .header__buttons {
        flex-direction: column;
        align-items: start;
    }
}

@media (min-width: 720px) {
    .header__menu_principal {
        display: flex;
        transform: translate3D(0, 0px, 0);
        visibility: visible;
        height: 80px;
        flex-direction: row;
        align-items: center;
    }
    .mobile_menu__button {
        display: none;
    }
    .header__button {
        display: none;
    }
    .header__buttons {
        flex-direction: initial;
        align-items: initial;
        display: flex;
    }
}

