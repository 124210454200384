.form {
	margin: 0;
	padding: 2em;
	max-width: 35em;
	width: 100%;

	@media screen and (max-width: 400px) {
		font-size: 0.8em;
	}

	.title {
		font-size: 2em;

		@media screen and (max-width: 500px) {
			font-size: 1.5em;
		}
	}

	.back_button {
		padding: 0;
		aspect-ratio: 1 / 1;
		width: 1.2em;
		font-size: 2em;
		margin-left: -5px;
	}

	.text {
		font-size: 1.1em;
		text-align: center;
	}

	.input {
		max-width: none;
		width: 100%;
		font-size: 1.2em;
	}

	.button {
		padding: 0.5em 2em;
	}
}
