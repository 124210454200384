.container {
	display: grid;
	grid-template-rows: auto 1fr;
	height: 100%;
	width: 100%;
	justify-items: center;
	align-items: start;
	align-content: start;

	.actions_field {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.menu {
    &__item {
        display: flex;
        align-items: center;
        gap: 0.5em;
    }
}

