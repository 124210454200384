.background {
    font-family: sans-serif;
    text-align: center;
    width: 97%;
    padding: 10px;
  
    .container {
      padding: 15px 0;
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
    }
  
    .notes {
      display: none;
      padding: 10px;
      p {
        text-align: left;
      }
    }
  }
  