@import "./products_form_inputs.scss";

@mixin products_form {
	&__form {
		width: 100%;
		padding: 20px;
		display: grid;
		grid-template-columns: 100%;
		justify-items: start;
		gap: 20px;
		max-width: 1200px;

		&__title {
			margin: 0;
			padding: 0;
			font-size: 2em;
		}

		&__go_back_button {
			background-color: transparent;
			border: none;
			padding: 5px 10px;
			border-radius: 20px;
			display: flex;
			gap: 10px;
			justify-content: center;
			align-items: center;
			outline: none;
			color: black;
			font-size: 0.8em;
			font-weight: bold;

			&:hover {
				background-color: #ececec;
			}
		}

		&__content {
			display: grid;
			padding-left: 60px;
			grid-template-columns: inherit;
			gap: inherit;
			width: 100%;
		}

		&__inputs {
			display: grid;
			gap: 20px;
			grid-template-columns: inherit;
			justify-items: start;
			max-width: 700px;
		}

		@include products_form_inputs;

		@media screen and (max-width: 850px) {
			&__content {
				padding: 0 20px;
			}
		}

		@media screen and (max-width: 500px) {
			&__title {
				font-size: 1.5em;
			}
		}
	}
}
