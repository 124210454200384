$size-tablet-bigger: 1200px;
$size-tablet: 1150px;
$size-mobile: 750px;
$size-mobile-small: 550px;

@mixin container {
	min-height: 100%;
	padding: 2em;
	background-color: #efe6d8;
	--border-radius: 1em;
}

@mixin content {
	max-width: 1400px;
}