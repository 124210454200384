.container {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto 1fr;
}

.content {
	height: 100%;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto 1fr;
	gap: 1em;
	padding: 20px;
	overflow-y: auto;

	.head {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 1em;
	}

	.breadcrumb {
		--color: black;
	}

	.data_grid {
		--row--aligment: center;

		&__actions {
			display: flex;
			gap: 0.3em;
		}

		&__icon {
			padding: 0.3em;
		}
	}
}

body .modal {
	padding: 25px;
	justify-items: center;
	max-width: 1200px;
	grid-template-rows: auto 1fr;
	gap: 2em;

	&__title {
		font-size: 30px;
	}

	&__form {
		width: 100%;
		max-width: none;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: center;
		gap: 20px;
		overflow-y: auto;

		--textfield-gray-color: #9e9e9e;
		--textfield-border-radius: 5px;
		--textfield-shadow: 0 3px 3px #aaa;
		--textfield-pad: 0.5em;

		&__textfield {
			max-width: none;
			overflow: initial;
			gap: 5px;
			--pad: var(--textfield-pad);
			--radius: var(--textfield-border-radius);

			&__input {
				border-color: gray;
				box-shadow: var(--textfield-shadow);

				&::placeholder {
					color: var(--textfield-gray-color);
				}
			}
		}

		&__select {
			--padding-inline: calc(var(--textfield-pad) * 2);
			box-shadow: var(--textfield-shadow);
		}

		&__textfield_read_only {
			border: 1px solid var(--textfield-gray-color);
			color: var(--textfield-gray-color);
			box-shadow: var(--textfield-shadow);
			padding: var(--textfield-pad) calc(2 * var(--textfield-pad));
			border-radius: var(--textfield-border-radius);
			line-height: normal;
			width: 100%;

			&--empty::after {
				content: "\00a0";
			}
		}
	}

	&__card {
		display: grid;
		justify-items: center;
		align-content: start;
		gap: 2em;
		width: 100%;
		max-width: 20em;
		padding: 20px;
		padding-bottom: 30px;

		border: 3px solid #0000;
		border-radius: 10px;
		background: linear-gradient(white, white) padding-box, linear-gradient(to bottom, #2c4154, #d49536) border-box;
		box-shadow: 0px 4px 4px #00000040;

		&__title {
			text-align: center;
		}

		&__content {
			display: grid;
			gap: 1em;
			width: 100%;
		}
	}

	&__buttons {
		display: flex;
		justify-self: end;
		gap: 1em;
	}
}

body .switch_modal {
	max-width: 28em;

	&__text {
		text-align: center;
	}

	&__buttons {
		display: flex;
		justify-self: end;
		gap: 1em;
	}
}
